import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'

const TextSplit = ({ page, module }) => {
  const content = page._rawContent.find(block => block._key === module._key)
  const maxWidth = module.width !== 'none' ? ' ' + module.width : false

  return (
    <section className="section">
      <div className="section--wrapper">
        {module.title ? (
          <div className="section--title">{module.title}</div>
        ) : null}

        <div className={`section--content${maxWidth || ''}`}>
          {content.intro ? (
            <BlockContent
              renderContainerOnSingleChild
              className="rc"
              blocks={content.intro}
              serializers={serializers}
            />
          ) : null}

          {content.column1 || content.column2 ? (
            <div className="section--columns grid">
              <div className="grid--item is-md-6">
                {content.column1 ? (
                  <BlockContent
                    renderContainerOnSingleChild
                    className="rc"
                    blocks={content.column1}
                    serializers={serializers}
                  />
                ) : null}
              </div>
              <div className="grid--item is-md-6">
                {content.column2 ? (
                  <BlockContent
                    renderContainerOnSingleChild
                    className="rc"
                    blocks={content.column2}
                    serializers={serializers}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default TextSplit
