import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'

const Hero = ({ hero, content }) => {
  return (
    <section className={`hero${hero.bg_color ? ` ${hero.bg_color}` : ``}`}>
      <div className="hero--wrapper">
        <div className="hero--content">
          <BlockContent
            renderContainerOnSingleChild
            className="rc"
            blocks={content}
            serializers={serializers}
          />
        </div>
      </div>
      {hero.bg_image && (
        <div className="hero--bg">
          <img src={hero.bg_image.asset.url} alt="" />
        </div>
      )}
    </section>
  )
}

export default Hero
