import React, { useEffect } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'

import LazyLoad from 'vanilla-lazyload'

const TextFull = ({ page, module }) => {
  const content = page._rawContent.find(block => block._key === module._key)
    .content
  const isReversed = module.display === 'right'
  const isFlush = module.isFlush === true
  const photo = module.image

  let lazy
  useEffect(() => {
    lazy = new LazyLoad({
      elements_selector: '.photo',
      threshold: -100,
      class_loaded: 'is-loaded',
      callback_loaded: el => {
        el.parentNode.classList.add('is-loaded')
      }
    })

    return () => {
      lazy.destroy()
    }
  }, [])

  return (
    <section className="section">
      <div className={`section--wrapper${isFlush ? ' is-flush' : ''}`}>
        {module.title ? (
          <div className="section--title">{module.title}</div>
        ) : null}
        <div className="grid is-flush">
          <div
            className={`grid--item is-md-7${isReversed ? ' is-md-last' : ''}`}
          >
            <figure
              className={`section--photo${
                isReversed && isFlush ? ' is-flush-right' : ''
              }${isFlush && !isReversed ? ' is-flush-left' : ''}`}
            >
              <div
                className="is-aspect is-aspect--custom"
                style={{
                  paddingTop: 100 / photo.asset.fluid.aspectRatio + '%'
                }}
              >
                <img
                  className="photo"
                  data-src={photo.asset.fluid.src}
                  data-srcset={photo.asset.fluid.srcSet}
                  data-sizes={photo.asset.fluid.sizes}
                  alt={photo.alt}
                />
              </div>
            </figure>
          </div>
          <div className="grid--item is-md-5">
            <div className="section--content">
              <BlockContent
                renderContainerOnSingleChild
                className="rc"
                blocks={content}
                serializers={serializers}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextFull
