import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'

const Banner = ({ page, module }) => {
  const { button } = module
  const content = page._rawContent.find(block => block._key === module._key)
    .content

  return (
    <aside className="banner">
      <div className="banner--wrapper">
        <div className="banner--content">
          <BlockContent
            renderContainerOnSingleChild
            className="rc"
            blocks={content}
            serializers={serializers}
          />
        </div>
        <div className="banner--cta">
          <Link
            to={`/${button.page.slug.current}/`}
            className={`btn ${button.color}`}
          >
            {button.cta}
          </Link>
        </div>
      </div>
    </aside>
  )
}

export default Banner
