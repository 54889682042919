import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'

import { imageUrlFor } from '../../lib/image-url'

import Callout from '../../modules/callout'
import TextFull from '../../modules/text-full'
import TextSplit from '../../modules/text-split'
import TextImage from '../../modules/text-image'
import Form from '../../modules/form'
import Resources from '../../modules/resources'
import Videos from '../../modules/videos'
import Team from '../../modules/team'
import Banner from '../../modules/banner'

export const Module = ({ page, module }) => getModule(page, module)

const getModule = (page, module) => {
  const type = module._type
  switch (type) {
    case 'callout':
      return <Callout page={page} module={module} />
    case 'textFull':
      return <TextFull page={page} module={module} />
    case 'textSplit':
      return <TextSplit page={page} module={module} />
    case 'textImage':
      return <TextImage page={page} module={module} />
    case 'form':
      return <Form page={page} module={module} />
    case 'banner':
      return <Banner page={page} module={module} />
    case 'resources':
      return <Resources module={module} />
    case 'videos':
      return <Videos module={module} />
    case 'team':
      return <Team module={module} />
    case 'divider':
      return (
        <div className="section--divider">
          <hr />
        </div>
      )
    default:
      return null
  }
}

export const serializers = {
  types: {
    block: props => {
      const { style = 'normal' } = props.node

      if (style === 'statement') {
        return <p className="is-statement">{props.children}</p>
      } else if (style === 'subhead') {
        return <p className="is-subhead">{props.children}</p>
      }

      return BlockContent.defaultSerializers.types.block(props)
    },
    figure: ({ node }) => {
      return (
        <figure className="rc-image">
          <img
            data-src={imageUrlFor(node)
              .quality(100)
              .url()}
            alt={node.alt}
            className="photo"
          />
        </figure>
      )
    }
  },
  marks: {
    highlight: ({ mark, children }) => {
      const { color } = mark
      return <span className={color}>{children}</span>
    },

    link: ({ mark, children }) => {
      const { blank, href } = mark
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      )
    },

    button: ({ mark, children }) => {
      const { color, page } = mark

      return (
        <Link to={`/${page.slug.current}/`} className={`btn ${color}`}>
          {children}
        </Link>
      )
    }
  }
}
