import React, { useEffect } from 'react'

import LazyLoad from 'vanilla-lazyload'

const Resources = ({ module }) => {
  let lazy
  useEffect(() => {
    lazy = new LazyLoad({
      elements_selector: '.photo',
      threshold: -100,
      class_loaded: 'is-loaded',
      callback_loaded: el => {
        el.parentNode.classList.add('is-loaded')
      }
    })

    return () => {
      lazy.destroy()
    }
  }, [])

  return (
    <section className="section">
      <div className="section--wrapper">
        {module.title ? (
          <div className="section--title">{module.title}</div>
        ) : null}
        <div className="section--content">
          {module.items.map((member, key) => {
            return (
              <div key={key} className="member">
                {member.headshot ? (
                  <figure className="member--headshot">
                    <div className="is-aspect is-aspect--square">
                      <img
                        data-src={member.headshot.asset.fixed.src}
                        alt={member.headshot.alt}
                        className="photo"
                      />
                    </div>
                  </figure>
                ) : null}

                <div className="member--details">
                  <h3 className="member--name">{member.name}</h3>
                  {member.title ? (
                    <p className="member--title is-subhead">{member.title}</p>
                  ) : null}

                  {member.description ? <p>{member.description}</p> : null}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Resources
