import React, { useEffect } from 'react'

import LazyLoad from 'vanilla-lazyload'

const Resources = ({ module }) => {
  let lazy
  useEffect(() => {
    lazy = new LazyLoad({
      elements_selector: '.photo',
      threshold: -100,
      class_loaded: 'is-loaded',
      callback_loaded: el => {
        el.parentNode.classList.add('is-loaded')
      }
    })

    return () => {
      lazy.destroy()
    }
  }, [])

  return (
    <section className="section">
      <div className="section--wrapper">
        {module.title ? (
          <div className="section--title">{module.title}</div>
        ) : null}
        <div className="section--content">
          <div className="grid is-spaced">
            {module.items.map((resource, key) => {
              return (
                <div key={key} className="grid--item is-sm-6">
                  <div className="resource">
                    <div className="resource--inner">
                      {resource.image ? (
                        <figure className="resource--preview">
                          <div className="is-aspect is-aspect--square">
                            <img
                              data-src={resource.image.asset.fixed.src}
                              alt={resource.image.alt}
                              className="photo"
                            />
                          </div>
                        </figure>
                      ) : null}
                      <div className="resource--details">
                        {resource.category ? (
                          <p className="resource--category is-subhead">
                            {resource.category}
                          </p>
                        ) : null}

                        <h3 className="resource--title">{resource.title}</h3>

                        {resource.description ? (
                          <p>{resource.description}</p>
                        ) : null}

                        <a
                          href={`${resource.download.asset.url}?dl`}
                          className="btn is-primary"
                        >
                          {resource.cta ? resource.cta : 'Download'}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Resources
