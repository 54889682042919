import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'

const Callout = ({ page, module }) => {
  const content = page._rawContent.find(block => block._key === module._key)
    .content

  return (
    <section className="callout">
      <div className="callout--wrapper">
        <div className="callout--content">
          <BlockContent
            renderContainerOnSingleChild
            className="rc"
            blocks={content}
            serializers={serializers}
          />
        </div>
      </div>
    </section>
  )
}

export default Callout
