import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// site components
import Dropdown from './dropdown'

const footer = () => {
  const data = useStaticQuery(graphql`
    {
      logoWhite: file(relativePath: { eq: "Hook-Logo-w.svg" }) {
        publicURL
      }
      menu: sanityMenu(slug: { current: { eq: "footer" } }) {
        title
        items {
          ... on SanityNavDropdown {
            title
            items {
              ... on SanityNavLink {
                title
                link
                isButton
              }
              ... on SanityNavPage {
                title
                page {
                  slug {
                    current
                  }
                }
                isButton
              }
            }
          }
          ... on SanityNavLink {
            title
            link
            isButton
          }
          ... on SanityNavPage {
            title
            page {
              slug {
                current
              }
            }
            isButton
          }
        }
      }
    }
  `)

  const { logoWhite, menu } = data
  return (
    <footer className="footer" role="contentinfo">
      <div className="footer--wrapper">
        <div className="footer--content">
          <figure className="footer--logo">
            <img src={logoWhite.publicURL} alt="Alt Description" />
          </figure>
          <nav className="footer-navigation">
            <ul>
              {menu.items.map((item, key) => {
                const hasDropdown = !!item.items

                if (hasDropdown) {
                  const dropdown = item.items

                  return (
                    <li key={key}>
                      <Dropdown title={item.title} items={dropdown} />
                    </li>
                  )
                } else {
                  const isLink = !!item.link

                  return (
                    <li key={key}>
                      {isLink ? (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={item.isButton ? 'btn' : ''}
                        >
                          {item.title}
                        </a>
                      ) : (
                        <Link
                          to={`/${item.page.slug.current}/`}
                          className={item.isButton ? 'btn' : ''}
                        >
                          {item.title}
                        </Link>
                      )}
                    </li>
                  )
                }
              })}
            </ul>
          </nav>
        </div>
        <div className="footer--disclaimer">
          <p className="is-m0">
            Contact us: <a href="mailto:info@hook.md">info@hook.md</a>
          </p>
          <p className="is-uc">&copy; {new Date().getFullYear()} Hook MD</p>
        </div>
      </div>
    </footer>
  )
}

export default footer
