import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// site components
import Dropdown from './dropdown'

const Header = ({ active }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "Hook-Logo.svg" }) {
        publicURL
      }
      menu: sanityMenu(slug: { current: { eq: "header" } }) {
        title
        items {
          ... on SanityNavDropdown {
            title
            items {
              ... on SanityNavLink {
                title
                link
                isButton
              }
              ... on SanityNavPage {
                title
                page {
                  slug {
                    current
                  }
                }
                isButton
              }
            }
          }
          ... on SanityNavLink {
            title
            link
            isButton
          }
          ... on SanityNavPage {
            title
            page {
              slug {
                current
              }
            }
            isButton
          }
        }
      }
    }
  `)

  // page data
  const { logo, menu } = data

  // menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isOpen = isMenuOpen

  const toggleMenu = open => {
    setIsMenuOpen(open)
    if (typeof document !== `undefined`) {
      document.body.classList.toggle('nav-open', open)
    }
  }

  return (
    <>
      <a href="#content" className="skip-link">
        Skip to Content
      </a>
      <header className="header">
        <div className="header--wrapper">
          <div className="header--content">
            <Link onClick={() => toggleMenu(false)} to="/" className="logo">
              <img src={logo.publicURL} alt="Hook" />
            </Link>
            <nav className="main-navigation" role="navigation">
              <button
                onClick={() => toggleMenu(!isOpen)}
                className="menu-toggle"
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-label="Toggle Menu"
              >
                <span className="close-icon" />
              </button>

              <ul>
                {menu.items.map((item, key) => {
                  const hasDropdown = !!item.items

                  if (hasDropdown) {
                    const dropdown = item.items

                    const activeDropdown =
                      dropdown.filter(
                        item => item.page && item.page.slug.current === active
                      ).length > 0

                    return (
                      <li
                        key={key}
                        className={activeDropdown ? 'is-active' : ''}
                      >
                        <Dropdown
                          title={item.title}
                          items={dropdown}
                          toggleMenu={toggleMenu}
                        />
                      </li>
                    )
                  } else {
                    const isLink = !!item.link
                    const activePage =
                      item.page && item.page.slug.current === active

                    return (
                      <li key={key} className={activePage ? 'is-active' : ''}>
                        {isLink ? (
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={item.isButton ? 'btn' : ''}
                          >
                            {item.title}
                          </a>
                        ) : (
                          <Link
                            onClick={() => toggleMenu(false)}
                            to={`/${item.page.slug.current}/`}
                            className={item.isButton ? 'btn' : ''}
                          >
                            {item.title}
                          </Link>
                        )}
                      </li>
                    )
                  }
                })}
              </ul>
              <div className="main-navigation--overlay" />
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
