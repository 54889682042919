import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'

const TextFull = ({ page, module }) => {
  const content = page._rawContent.find(block => block._key === module._key)
    .content
  const maxWidth = module.width !== 'none' ? ' ' + module.width : false

  return (
    <section className="section">
      <div className="section--wrapper">
        {module.title ? (
          <div className="section--title">{module.title}</div>
        ) : null}
        <div className={`section--content${maxWidth || ''}`}>
          <BlockContent
            renderContainerOnSingleChild
            className="rc"
            blocks={content}
            serializers={serializers}
          />
        </div>
      </div>
    </section>
  )
}

export default TextFull
