import React, { useEffect } from 'react'
import Vimeo from '@u-wave/react-vimeo'

import LazyLoad from 'vanilla-lazyload'

const Videos = ({ module }) => {
  let lazy
  useEffect(() => {
    lazy = new LazyLoad({
      elements_selector: '.photo',
      threshold: -100,
      class_loaded: 'is-loaded',
      callback_loaded: el => {
        el.parentNode.classList.add('is-loaded')
      }
    })

    return () => {
      lazy.destroy()
    }
  }, [])

  return (
    <section className="section">
      <div className="section--wrapper">
        {module.title ? (
          <div className="section--title">{module.title}</div>
        ) : null}
        <div className="section--content">
          <div className="grid is-spaced">
            {module.items.map((video, key) => {
              return (
                <div key={key} className="grid--item is-sm-6">
                  <div className="video">
                    <div className="video--inner">
                      <div className="video--player">
                        <Vimeo
                          video={video.url}
                          className="is-aspect is-aspect--video"
                          color="61defa"
                          showByline={false}
                          showPortrait={false}
                          showTitle={false}
                        />
                      </div>
                      <div className="video--details">
                        <h3 className="video--title">{video.title}</h3>
                        {video.description ? <p>{video.description}</p> : null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Videos
