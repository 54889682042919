import React, { useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '../components/scaffolding/module'
import { useForm } from 'react-hook-form'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = ({ page, module }) => {
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { handleSubmit, register, reset, errors } = useForm()

  const resetForm = () => {
    setError(false)
    reset()
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    setSubmitting(true)
    setError(false)

    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...data
      })
    })
      .then(() => {
        setSubmitting(false)
        setError(false)
        setSuccess(true)
      })
      .catch(error => {
        setSuccess(false)
        setError(true)
        console.log(error)
      })
  }

  const content = page._rawContent.find(block => block._key === module._key)
    .success

  return (
    <section className="section">
      <div className="section--wrapper">
        <div className="section--content">
          <form
            name={module.submit ? module.submit : 'Contact Us'}
            className="form"
            data-netlify="true"
            data-netlify-honeypot="person"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!error && !success && (
              <>
                <div className={`control${errors.fname ? ' has-error' : ''}`}>
                  <input
                    type="text"
                    name="person"
                    className="control--pot"
                    ref={register}
                  />
                  <input
                    type="hidden"
                    name="form-name"
                    value={module.submit ? module.submit : 'Contact Us'}
                    ref={register}
                  />
                  <label htmlFor="fname" className="control--label">
                    First Name
                  </label>
                  <input
                    name="fname"
                    type="text"
                    autoComplete="name"
                    ref={register({
                      required: 'This field is required.'
                    })}
                    onFocus={e => {
                      e.target.parentNode.classList.add('is-filled')
                    }}
                    onBlur={e => {
                      const value = e.target.value
                      e.target.parentNode.classList.toggle('is-filled', value)
                    }}
                  />
                  {errors.fname && (
                    <span role="alert" className="control--error">
                      {errors.fname.message}
                    </span>
                  )}
                </div>
                <div className={`control${errors.lname ? ' has-error' : ''}`}>
                  <label htmlFor="lname" className="control--label">
                    Last Name
                  </label>
                  <input
                    name="lname"
                    type="text"
                    autoComplete="name"
                    ref={register({
                      required: 'This field is required.'
                    })}
                    onFocus={e => {
                      e.target.parentNode.classList.add('is-filled')
                    }}
                    onBlur={e => {
                      const value = e.target.value
                      e.target.parentNode.classList.toggle('is-filled', value)
                    }}
                  />
                  {errors.lname && (
                    <span role="alert" className="control--error">
                      {errors.lname.message}
                    </span>
                  )}
                </div>
                <div className={`control${errors.company ? ' has-error' : ''}`}>
                  <label htmlFor="company" className="control--label">
                    Company
                  </label>
                  <input
                    name="company"
                    type="text"
                    autoComplete="name"
                    ref={register({
                      required: 'This field is required.'
                    })}
                    onFocus={e => {
                      e.target.parentNode.classList.add('is-filled')
                    }}
                    onBlur={e => {
                      const value = e.target.value
                      e.target.parentNode.classList.toggle('is-filled', value)
                    }}
                  />
                  {errors.company && (
                    <span role="alert" className="control--error">
                      {errors.company.message}
                    </span>
                  )}
                </div>
                <div className={`control${errors.email ? ' has-error' : ''}`}>
                  <label htmlFor="email" className="control--label">
                    E-Mail
                  </label>
                  <input
                    name="email"
                    type="email"
                    inputMode="email"
                    autoComplete="email"
                    ref={register({
                      required: 'This field is required.',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'invalid email address'
                      }
                    })}
                    onFocus={e => {
                      e.target.parentNode.classList.add('is-filled')
                    }}
                    onBlur={e => {
                      const value = e.target.value
                      e.target.parentNode.classList.toggle('is-filled', value)
                    }}
                  />
                  {errors.email && (
                    <span role="alert" className="control--error">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className={`control${errors.message ? ' has-error' : ''}`}>
                  <label htmlFor="message" className="control--label">
                    Message
                  </label>
                  <textarea
                    name="message"
                    rows="5"
                    ref={register({
                      required: 'This field is required.'
                    })}
                    onFocus={e => {
                      e.target.parentNode.classList.add('is-filled')
                    }}
                    onBlur={e => {
                      const value = e.target.value
                      e.target.parentNode.classList.toggle('is-filled', value)
                    }}
                  />
                  {errors.message && (
                    <span role="alert" className="control--error">
                      {errors.message.message}
                    </span>
                  )}
                </div>
                <div className="form--note">
                  <p>{module.note}</p>
                </div>
                <button
                  type="submit"
                  className={`btn is-primary is-large is-block${
                    submitting ? ' is-loading' : ''
                  }`}
                >
                  {module.submit ? module.submit : 'Submit'}
                </button>
              </>
            )}

            {success && (
              <div className="form--success">
                <div className="form--success-inner">
                  <BlockContent
                    renderContainerOnSingleChild
                    className="rc"
                    blocks={content}
                    serializers={serializers}
                  />
                </div>
              </div>
            )}

            {error && (
              <div className="form--error">
                <div className="form--error-inner">
                  <h3>Oops!</h3>
                  <p>Sorry, there was an error.</p>
                  <p>
                    <button className="btn" onClick={resetForm}>
                      try again
                    </button>
                  </p>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  )
}

export default Form
